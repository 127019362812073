@import '@/styles/breakpoints.module';
@import '@/styles/typography.module';

.appBtnsBlock {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  @include tablet {
    justify-content: center;
    align-items: center;
  }

  & > *,
  & button {
    width: 100%;
    @include tablet {
      width: auto;
    }
  }
}

.appBtnSpacing {
  margin-top: 12px;
  @include tablet {
    margin-top: 0;
    margin-inline-start: 12px;
  }
}

.steps.steps {
  --step-list-gap: 24px;
  --step-marker-size: 36px;
  @include tablet {
    --step-marker-size: 44px;
    --step-list-gap: 32px;
  }
  @include desktop {
    --step-list-gap: 40px;
  }
}

.mainPic {
  margin-top: var(--vstack-spacing-sm);
  margin-inline: auto;
  @include tablet {
    margin-top: var(--vstack-spacing-md);
  }
  @include desktop {
    margin-top: 0;
    margin-inline: 0;
    margin-inline-start: 12px;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  @include tablet {
    width: 457px;
    flex-shrink: 0;
  }
}

.blockSteps {
  @include desktop {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.leftSide {
  display: grid;
  gap: 24px;
  @include tablet {
    gap: 32px;
    max-width: 623px;
  }
}

.qrBlock {
  display: none;
  @include desktop {
    display: block;
  }
}

.titleQrBlock {
  margin-bottom: 16px;
}
